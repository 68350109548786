<template>
  <div class="content">
    <van-nav-bar :fixed="true" left-arrow placeholder :title="$t('newBank.walletList.pageTitle')"
      @click-left="$router.go(-1)" />
    <div class="list-com">
      <div class="list-item" v-for="(item, index) in dataList" :key="index" @click="handleFind(item)">
        <div class="img-com" v-if="!verConfig.banEditBank">
          <!-- <img
            class="item-delete"
            :src="require('@/assets/new/delete.png')"
            alt="delete"
            @click.stop="deleteWallet(item)"
          /> -->
          <van-icon name="delete-o" size="24" color="var(--sizeColor)" @click.stop="deleteWallet(item)" />
        </div>
        <p>
          {{ item.open ? $t("newBank.walletList.name"): $t("newBank.usdt.name")+':' }}
          <span>{{ item.name }}</span>
        </p>
        <p>
          {{ item.open ? $t("newBank.walletList.coinType"): $t("newBank.usdt.bankName")+':' }}
          <span>{{ item.bank }}</span>
        </p>
        <p v-if="!!item.open">
          {{ `${$t("newBank.walletList.chainType")} ` }}
          <span>{{ item.open }}</span>
        </p>
        <p>
          {{ item.open ? $t("newBank.walletList.walletAddress") :$t('newBank.usdt.bankCard')+':' }}
          <span>{{ item.card }}</span>
        </p>
        <p>
          {{ `${$t("newBank.walletList.addDate")} ` }}
          <span>
            {{
              $format(
                item.indate * 1000,
                verConfig.dateFormat || "MM/dd/yyyy HH:mm:ss"
              )
            }}
          </span>
        </p>
      </div>
    </div>
    <img v-if="!dataList.length && !verConfig.banEditBank" :src="verConfig.nullImg" class="none" />
    <button class="commit-btn" @click.stop="handleFind()" v-if="(!verConfig.banEditBank && dataList.length < 1) ||
      verConfig.allowedMoreCard
      ">
      {{ $t("newBank.walletList.btnText") }}
    </button>
  </div>
</template>

<script>
import { NavBar, List, Toast, Dialog, Icon } from "vant";
import { GET_USERS_BANK, DELETE_MONEY_WALLET } from "@/api";
import noDataBack from "@/assets/new/noData.png";
import { formatDate } from "@/utils/tools";
import { join } from "path";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Icon.name]: Icon
  },
  data() {
    return {
      loading: false,
      finished: false,
      dataList: [],
    };
  },
  mounted() {
    this.getLists();
  },
  computed: {},
  methods: {
    handleFind(item) {
      if (item) {
        if (this.verConfig.banEditBank) return;
        this.$router.push(`/add-bank-card?data=${JSON.stringify(item)}`);
      } else {
        this.$router.push("/add-bank-card");
      }
    },
    deleteWallet(item) {
      const { id } = item;

      Dialog.confirm({
        title: "",
        message: this.$t("newBank.walletList.deleteConfirmText"),
        cancelButtonText: this.$t("newBank.walletList.btnCancle"),
        confirmButtonText: this.$t("newBank.walletList.btnConfirm"),
      }).then(() => {
        this.deleteMoneyWalletApi(id);
      });
    },
    itemClick(page) {
      this.$router.push({ name: page });
    },
    /* api */
    getLists() {
      this.dataList = [];
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USERS_BANK()
        .then((r) => {
          Toast.clear();
          this.loading = false;
          const { data, msg, ret } = r.data;
          if (ret !== 1) return this.$toast(msg);
          this.dataList = data || [];
          if (!this.dataList.length) {
            Dialog.confirm({
              title: this.$t("newAdd.warmTitle"),
              confirmButtonText: this.$t("Button.confirm"),
              cancelButtonText: this.$t("Button.cancle"),
              message: this.verConfig.needBank
                ? this.$t("newAdd.NotHaveCard")
                : this.$t("newAdd.NotHaveWallet"),
            })
              .then((res) => {
                this.itemClick("BankCardAdd");
              })
              .catch((err) => {
                this.$router.back();
              });
          }
        })
        .catch((e) => {
          Toast.clear();
        });
    },
    async deleteMoneyWalletApi(id) {
      const res = await DELETE_MONEY_WALLET({ id });
      const { ret, msg } = res.data;
      if (ret !== 1) return this.$toast(msg);
      this.$toast(this.$t("newBank.walletList.successHint"));
      this.getLists();
    }, // 删除钱包
    /* other */
    dateEvent(indate) {
      return formatDate("Y-m-d H:i:s", indate);
    },
  },
};
</script>

<style lang='less' scoped>
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.content {
  width: 100%;
  height: 100vh;
  // background-color: #f0ebef;
  background-color: @background;

  >.list-com {
    padding: calc(6rem / 16);
    overflow-y: auto;

    >.list-item {
      padding: calc(11rem / 16);
      border-radius: calc(5rem / 16);
      // background-color: #fff;
      background-color: var(--bg);
      margin-bottom: calc(8rem / 16);

      >.img-com {
        width: 100%;
        display: flex;
        justify-content: end;

        >.item-delete {
          width: calc(22rem / 16);
          height: calc(22rem / 16);
        }
      }

      >p {
        margin-top: calc(8rem / 16);
        font-size: calc(18rem / 16);
        // color: #757c90;
        color: @sizeColor;

        span {
          // color: #333;
          color: @Color;
          word-break: break-all;
        }
      }

      >p:nth-child(2) {
        margin: 0;
      }
    }
  }

  .none {
    width: 100%;
  }

  >.commit-btn {
    width: calc(100% - (22rem / 16));
    max-width: calc(750px - 22rem / 16);
    height: calc(44rem / 16);
    border-radius: calc(551rem / 16);
    position: absolute;
    left: 50%;
    bottom: calc(99rem / 16);
    transform: translate(-50%, 0);
    border: none;
    font-size: calc(12rem / 16);
    background-color: var(--newBtn);
    color: var(--btnsizeColor);
  }
}
</style>